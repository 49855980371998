<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
                <div class="col-6">
                  <c-text
                    :required="true"
                    :editable="editable"
                    label="방지시설명"
                    name="envWaterMstPreventiveName"
                    v-model="data.envWaterMstPreventiveName">
                  </c-text>
                </div>
                <div class="col-6">
                  <c-text
                    :required="true"
                    :editable="editable"
                    label="방지시설 관리번호"
                    name="envWaterMstPreventiveNo"
                    v-model="data.envWaterMstPreventiveNo">
                  </c-text>
                </div>
                <div class="col-4">
                  <c-plant :required="true" :editable="editable" type="edit" name="plantCd" v-model="data.plantCd" />
                </div>
                <div class="col-4">
                  <c-dept type="edit" :editable="editable" label="관리부서" name="deptCd" v-model="data.deptCd" />
                </div>
                <div class="col-4">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    label="사용여부"
                    name="useFlag"
                    v-model="data.useFlag"
                  />
                </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="grid"
          title="기본 가동(조업)시간"
          tableId="grid"
          :columns="grid.columns"
          :data="grid.data"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
          gridHeight="120px"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="방지시설 고장이력" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-if="editable && popupParam.envWaterMstPreventiveId" label="등록" icon="add" @btnClicked="addData2" />
              <c-btn v-if="editable && saveable" label="삭제" icon="remove" @btnClicked="delData2" />
              <c-btn
                v-if="editable && saveable"
                :url="saveUrl2"
                :isSubmit="isSave2"
                :param="data2"
                :mappingType="saveType2"
                label="저장"
                icon="save"
                @beforeAction="saveData2"
                @btnCallback="saveCallback2" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
              <c-table
                ref="grid2"
                title="고장이력"
                tableId="grid2"
                :columnSetting="false"
                :isFullScreen="false"
                :columns="grid2.columns"
                :data="grid2.data"
                gridHeight="300px"
                @rowClick="rowClick">
              </c-table>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
              <q-form ref="editForm2">
                <div class="row">
                  <div class="col-12">
                    <c-datepicker
                      :required="true"
                      :range="true"
                      :editable="editable && dataeditable"
                      label="고장기간"
                      name="breakdownDts"
                      v-model="data2.breakdownDts"
                    />
                  </div>
                  <div class="col-12">
                    <c-text
                      :editable="editable && dataeditable"
                      label="고장상태"
                      name="breakdownStatus"
                      v-model="data2.breakdownStatus">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <c-text
                      :editable="editable && dataeditable"
                      label="조치사항"
                      name="breakdownAction"
                      v-model="data2.breakdownAction">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <c-text
                      :editable="editable && dataeditable"
                      label="특기사항"
                      name="remark"
                      v-model="data2.remark">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <c-datepicker
                      :editable="editable && dataeditable"
                      label="조치일"
                      name="bactionDt"
                      v-model="data2.bactionDt"
                    />
                  </div>
                </div>
              </q-form>
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'water-master01-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envWaterMstPreventiveId: '',
          plantCd: null,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
        envWaterMstPreventiveId: '',  // 수질방지시설 일련번호
        envWaterMstPreventiveName: '',  // 수질방지시설명
        envWaterMstPreventiveNo: '',  // 수질방지시설 관리번호
        deptCd: '',  // 관리부서 코드
        useFlag: 'Y',  // 사용여부
        oprTime01: 'N',  // 기본 가동시간 01시
        oprTime02: 'N',  // 기본 가동시간 02시
        oprTime03: 'N',  // 기본 가동시간 03시
        oprTime04: 'N',  // 기본 가동시간 04시
        oprTime05: 'N',  // 기본 가동시간 05시
        oprTime06: 'N',  // 기본 가동시간 06시
        oprTime07: 'N',  // 기본 가동시간 07시
        oprTime08: 'N',  // 기본 가동시간 08시
        oprTime09: 'N',  // 기본 가동시간 09시
        oprTime10: 'N',  // 기본 가동시간 10시
        oprTime11: 'N',  // 기본 가동시간 11시
        oprTime12: 'N',  // 기본 가동시간 12시
        oprTime13: 'N',  // 기본 가동시간 13시
        oprTime14: 'N',  // 기본 가동시간 14시
        oprTime15: 'N',  // 기본 가동시간 15시
        oprTime16: 'N',  // 기본 가동시간 16시
        oprTime17: 'N',  // 기본 가동시간 17시
        oprTime18: 'N',  // 기본 가동시간 18시
        oprTime19: 'N',  // 기본 가동시간 19시
        oprTime20: 'N',  // 기본 가동시간 20시
        oprTime21: 'N',  // 기본 가동시간 21시
        oprTime22: 'N',  // 기본 가동시간 22시
        oprTime23: 'N',  // 기본 가동시간 23시
        oprTime24: 'N',  // 기본 가동시간 24시
      },
      data2: {
        envWaterMstPreventiveId: '',  // 수질방지시설 일련번호
        envWaterMstPreventiveBreakdownId: '',  // 수질방지시설 고장이력 일련번호
        breakdownStartDt: '',  // 고장기간 시작일
        breakdownEndDt: '',  // 고장기간 종료일
        breakdownDts: [],  // 고장기간
        breakdownStatus: '',  // 고장상태
        breakdownAction: '',  // 조치사항
        remark: '',  // 특기사항
        bactionDt: '',  // 조치일
      },
      grid2: {
        columns: [
          {
            name: 'breakdownDtStr',
            field: 'breakdownDtStr',
            label: '고장기간',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'breakdownStatus',
            field: 'breakdownStatus',
            label: '고장상태',
            align: 'center',
            sortable: true,
          },
          {
            name: 'bactionDt',
            field: 'bactionDt',
            label: '조치일',
            align: 'center',
            style: 'width:180px',
            sortable: true,
          },
        ],
        data: [],
      },
      grid: {
        columns: [
          {
            name: 'oprTime01',
            field: 'oprTime01',
            label: '1',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime02',
            field: 'oprTime02',
            label: '2',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime03',
            field: 'oprTime03',
            label: '3',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime04',
            field: 'oprTime04',
            label: '4',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime05',
            field: 'oprTime05',
            label: '5',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime06',
            field: 'oprTime06',
            label: '6',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime07',
            field: 'oprTime07',
            label: '7',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime08',
            field: 'oprTime08',
            label: '8',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime09',
            field: 'oprTime09',
            label: '9',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime10',
            field: 'oprTime10',
            label: '10',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime11',
            field: 'oprTime11',
            label: '11',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime12',
            field: 'oprTime12',
            label: '12',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime13',
            field: 'oprTime13',
            label: '13',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime14',
            field: 'oprTime14',
            label: '14',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime15',
            field: 'oprTime15',
            label: '15',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime16',
            field: 'oprTime16',
            label: '16',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime17',
            field: 'oprTime17',
            label: '17',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime18',
            field: 'oprTime18',
            label: '18',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime19',
            field: 'oprTime19',
            label: '19',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime20',
            field: 'oprTime20',
            label: '20',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime21',
            field: 'oprTime21',
            label: '21',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime22',
            field: 'oprTime22',
            label: '22',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime23',
            field: 'oprTime23',
            label: '23',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
          {
            name: 'oprTime24',
            field: 'oprTime24',
            label: '24',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      saveUrl: transactionConfig.env.water.mst.preventive.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      isSave2: false,
      saveUrl2: transactionConfig.env.water.mst.preventive.breakdown.insert.url,
      saveType2: 'POST',
      listUrl2: '',
      detailUrl2: '',
      insertUrl2: '',
      updateUrl2: '',
      deleteUrl2: '',
      saveable: false,
      dataeditable: false,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.water.mst.preventive.get.url;
      this.saveUrl = transactionConfig.env.water.mst.preventive.insert.url;
      
      this.dataeditable = false;
      this.listUrl2 = selectConfig.env.water.mst.preventive.breakdown.list.url;
      this.detailUrl2 = selectConfig.env.water.mst.preventive.breakdown.get.url;
      this.insertUrl2 = transactionConfig.env.water.mst.preventive.breakdown.insert.url;
      this.updateUrl2 = transactionConfig.env.water.mst.preventive.breakdown.update.url;
      this.deleteUrl2 = transactionConfig.env.water.mst.preventive.breakdown.delete.url;

      this.getDetail();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getDataList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl2;
      this.$http.type = 'GET';
      this.$http.param = {envWaterMstPreventiveId: this.popupParam.envWaterMstPreventiveId, plantCd: this.popupParam.plantCd};
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
      this.saveable = false;
    },
    getDetail() {
      if (this.popupParam.envWaterMstPreventiveId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envWaterMstPreventiveId: this.popupParam.envWaterMstPreventiveId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.grid.data = [];
          this.grid.data.push(_result.data);
          this.updateMode = true;
          this.getDataList();
        },);
      } else {
        this.grid.data.push({
          oprTime01: 'N',  // 기본 가동시간 01시
          oprTime02: 'N',  // 기본 가동시간 02시
          oprTime03: 'N',  // 기본 가동시간 03시
          oprTime04: 'N',  // 기본 가동시간 04시
          oprTime05: 'N',  // 기본 가동시간 05시
          oprTime06: 'N',  // 기본 가동시간 06시
          oprTime07: 'N',  // 기본 가동시간 07시
          oprTime08: 'N',  // 기본 가동시간 08시
          oprTime09: 'N',  // 기본 가동시간 09시
          oprTime10: 'N',  // 기본 가동시간 10시
          oprTime11: 'N',  // 기본 가동시간 11시
          oprTime12: 'N',  // 기본 가동시간 12시
          oprTime13: 'N',  // 기본 가동시간 13시
          oprTime14: 'N',  // 기본 가동시간 14시
          oprTime15: 'N',  // 기본 가동시간 15시
          oprTime16: 'N',  // 기본 가동시간 16시
          oprTime17: 'N',  // 기본 가동시간 17시
          oprTime18: 'N',  // 기본 가동시간 18시
          oprTime19: 'N',  // 기본 가동시간 19시
          oprTime20: 'N',  // 기본 가동시간 20시
          oprTime21: 'N',  // 기본 가동시간 21시
          oprTime22: 'N',  // 기본 가동시간 22시
          oprTime23: 'N',  // 기본 가동시간 23시
          oprTime24: 'N',  // 기본 가동시간 24시
        })
      }
    },
    saveInfo() {
      if (this.popupParam.envWaterMstPreventiveId) {
        this.saveUrl = transactionConfig.env.water.mst.preventive.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.water.mst.preventive.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.data.oprTime01 = this.grid.data[0].oprTime01;  // 기본 가동시간 01시
              this.data.oprTime02 = this.grid.data[0].oprTime02;  // 기본 가동시간 02시
              this.data.oprTime03 = this.grid.data[0].oprTime03;  // 기본 가동시간 03시
              this.data.oprTime04 = this.grid.data[0].oprTime04;  // 기본 가동시간 04시
              this.data.oprTime05 = this.grid.data[0].oprTime05;  // 기본 가동시간 05시
              this.data.oprTime06 = this.grid.data[0].oprTime06;  // 기본 가동시간 06시
              this.data.oprTime07 = this.grid.data[0].oprTime07;  // 기본 가동시간 07시
              this.data.oprTime08 = this.grid.data[0].oprTime08;  // 기본 가동시간 08시
              this.data.oprTime09 = this.grid.data[0].oprTime09;  // 기본 가동시간 09시
              this.data.oprTime10 = this.grid.data[0].oprTime10;  // 기본 가동시간 10시
              this.data.oprTime11 = this.grid.data[0].oprTime11;  // 기본 가동시간 11시
              this.data.oprTime12 = this.grid.data[0].oprTime12;  // 기본 가동시간 12시
              this.data.oprTime13 = this.grid.data[0].oprTime13;  // 기본 가동시간 13시
              this.data.oprTime14 = this.grid.data[0].oprTime14;  // 기본 가동시간 14시
              this.data.oprTime15 = this.grid.data[0].oprTime15;  // 기본 가동시간 15시
              this.data.oprTime16 = this.grid.data[0].oprTime16;  // 기본 가동시간 16시
              this.data.oprTime17 = this.grid.data[0].oprTime17;  // 기본 가동시간 17시
              this.data.oprTime18 = this.grid.data[0].oprTime18;  // 기본 가동시간 18시
              this.data.oprTime19 = this.grid.data[0].oprTime19;  // 기본 가동시간 19시
              this.data.oprTime20 = this.grid.data[0].oprTime20;  // 기본 가동시간 20시
              this.data.oprTime21 = this.grid.data[0].oprTime21;  // 기본 가동시간 21시
              this.data.oprTime22 = this.grid.data[0].oprTime22;  // 기본 가동시간 22시
              this.data.oprTime23 = this.grid.data[0].oprTime23;  // 기본 가동시간 23시
              this.data.oprTime24 = this.grid.data[0].oprTime24;  // 기본 가동시간 24시
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envWaterMstPreventiveId = result.data.envWaterMstPreventiveId;
      this.popupParam.plantCd = result.data.plantCd;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.updateMode = true;
      this.$http.url = this.detailUrl2;
      this.$http.param = {envWaterMstPreventiveBreakdownId: row.envWaterMstPreventiveBreakdownId}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data2 = _result.data;
        this.data2.breakdownDts = [this.data2.breakdownStartDt, this.data2.breakdownEndDt]
        this.saveUrl2 = this.updateUrl2;
        this.saveType2 = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addData2() {
      this.saveable = true;
      this.saveUrl2 = this.insertUrl2;
      this.saveType2 = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data2 = {
        envWaterMstPreventiveId: this.popupParam.envWaterMstPreventiveId,  // 수질방지시설 일련번호
        envWaterMstPreventiveBreakdownId: '',  // 수질방지시설 고장이력 일련번호
        breakdownStartDt: '',  // 고장기간 시작일
        breakdownEndDt: '',  // 고장기간 종료일
        breakdownDts: [],
        breakdownStatus: '',  // 고장상태
        breakdownAction: '',  // 조치사항
        remark: '',  // 특기사항
        bactionDt: '',  // 조치일
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data2 = {
        envWaterMstPreventiveId: this.popupParam.envWaterMstPreventiveId,  // 수질방지시설 일련번호
        envWaterMstPreventiveBreakdownId: '',  // 수질방지시설 고장이력 일련번호
        breakdownStartDt: '',  // 고장기간 시작일
        breakdownEndDt: '',  // 고장기간 종료일
        breakdownDts: [],
        breakdownStatus: '',  // 고장상태
        breakdownAction: '',  // 조치사항
        remark: '',  // 특기사항
        bactionDt: '',  // 조치일
      };
    },
    saveData2() {
      this.$refs['editForm2'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data2.breakdownStartDt = this.data2.breakdownDts[0];
              this.data2.breakdownEndDt = this.data2.breakdownDts[1];
              this.data2.regUserId = this.$store.getters.user.userId;
              this.data2.chgUserId = this.$store.getters.user.userId;
              this.isSave2 = !this.isSave2;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDataList();
    },
    delData2() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '고장이력을 삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl2, this.data2.envWaterMstPreventiveBreakdownId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getDataList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>